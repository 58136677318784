<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {
    computed,
    inject,
    nextTick,
    onBeforeMount,
    onBeforeUnmount, onMounted,
    onUnmounted,
    reactive,
    ref,
    watch
} from "vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import Pagination from "../../Components/Pagination.vue";
import Plyr from "plyr";
import FormSelectClient from "../../Components/FormSelectClient.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import axios from "axios";
import FormMultiSelect from "../../Components/FormMultiSelect.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const client = ref(null)
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
function getUser(userId) {
    loading.value = true;
    axios.get("/api/clients/" + userId)
        .then(response => {
            client.value = response.data;
            clientPreSelected.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getUser(router.currentRoute.value.params.id)
    getClientCalls("/api/calls")
    getClientRequests("/api/client-requests")
})

const activeTab = ref('requestsTab')

const loadingCalls = ref(true)
const calls = ref([])
function getClientCalls(urlToFetch) {
    loadingCalls.value = true;
    calls.value = [];
    axios.get(urlToFetch, {
        params: {
            client_id: router.currentRoute.value.params.id,
            sortingField: 'created_at',
            sortingOrder: 'desc'
        }
    })
    .then(response => {
        paginationAndDataMapping(response.data, 'calls')
    })
    .catch(error => {
        httpErrorsHandler(error);
        loadingCalls.value = false;
    });
}

const loadingClientRequests = ref(true)
const clientRequests = ref([])
function getClientRequests(urlToFetch) {
    loadingClientRequests.value = true;
    clientRequests.value = [];
    axios.get(urlToFetch, {
        params: {
            client_id: router.currentRoute.value.params.id,
            sortingField: 'created_at',
            sortingOrder: 'desc'
        }
    })
    .then(response => {
        paginationAndDataMapping(response.data, 'clientRequests')
    })
    .catch(error => {
        httpErrorsHandler(error);
        loadingClientRequests.value = false;
    });
}
const linksClientRequests = ref([]);
const metaClientRequests = ref([]);
const linksCalls = ref([]);
const metaCalls = ref([]);
function paginationAndDataMapping(response, type) {
    if (type === 'clientRequests'){
        clientRequests.value = response.data;
        linksClientRequests.value = response.links;
        metaClientRequests.value = response.meta;
        loadingClientRequests.value = false;
    }
    if (type === 'calls'){
        calls.value = response.data;
        linksCalls.value = response.links;
        metaCalls.value = response.meta;
        loadingCalls.value = false;
    }
}

function defineCallTypeBadge(call) {
    if (call.data.direction === 'out') {
        return 'out';
    }
    if (call.data.direction === 'in' && call.data.last_state === 'missed') {
        return 'missed';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'voicemail' || call.data.voicemail_link !== null)) {
        return 'voicemail';
    }
    if (call.data.direction === 'in' && (call.data.last_state === 'hangup' || call.data.last_state === 'answered' || call.data.last_state === 'ringing')) {
        return 'in';
    }
}
let players = [];
watch(loadingCalls, async (newVal) => {
    if (loadingCalls.value === false) {
        await nextTick();
        players.forEach(player => {
            if (player) {
                player.destroy();
            }
        });
        players = Array.from(document.querySelectorAll('.players')).map(p => new Plyr(p,{
            controls: ['play', 'progress'],
            volume: 1,
            displayDuration: false,
        }));
    }
});

onUnmounted(() => {
    players.forEach(player => {
        if (player) {
            player.destroy();
        }
    });
});

const callToDisplay = ref(null);
function displayCallDetails(call){
    callToDisplay.value = call;
    const modalCallDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalCallDetails'))
    modalCallDetails.show()
}

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const loadingClientRequestCreate = ref(false)
const isInitialized = ref(true)
const form = reactive( {
    tags: [],
    client_id: null,
    center_id: null,
    status: 'pending',
    car_brand_id: null,
    car_model_id: null,
    car_version_id: null,
    car_engine_id: null,
    car_stage: null,
    contact_reason: null,
    vehicle_type: null,
    notes: null,
    is_on_promotion: false,
    request_origin: 'RingOver',
})
const centerPreSelected = ref([])
const clientPreSelected = ref([])
onMounted(() => {
    form.vehicle_type = 'car';
})
onBeforeMount(() => {
    if (authUser.value.centers.length >= 1) {
        centerPreSelected.value = {
            data: {
                id: authUser.value.centers[0].id,
                name: authUser.value.centers[0].name
            }
        }
    }
})
function createClientRequest() {

    loadingClientRequestCreate.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (key === 'tags') {
            return value.forEach((tag) => {
                formData.append(`${key}[]`, tag.id);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/client-requests", formData)
        .then(response => {
            $toast.success('Demande client créé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });

            router.push({ name: 'client-requests.show', params: { id: response.data.data.id } })

        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingClientRequestCreate.value = false;
        });
}
function showModalCreateClientRequest() {
    const modalCreateClientRequest = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalCreateClientRequest'))
    modalCreateClientRequest.show()
}
const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_brand_id = null;
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carModels.value = [];
            if (form.car_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?car_brand_id=' + form.car_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_version_id = null;
            form.car_engine_id = null;
            carVersions.value = [];
            if (form.car_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?car_model_id=' + form.car_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name === null ? model.data.from : model.data.from + ' - ' + model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_version_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            carEngines.value = [];
            form.car_engine_id = null;
            if (form.car_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&car_version_id=' + form.car_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_fr + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_engine_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            if (showEngineDetails.value === true) {
                showEngineDetails.value = false;
            }
        }
    },
    { deep: true }
);
//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function calculatePrice(type, price) {

    let basePrice = price;
    let priceStage1 = 0;
    let priceStage2 = 0;
    let priceE85 = 0;
    let priceE85plus = 0;

    if (brandConfig.brand === 'rstronic') {
        basePrice = price - 100;
        priceStage1 = Math.max(basePrice, 350);
        priceStage2 = priceStage1 + 100;
        priceE85 = Math.max(priceStage1, 490);
        priceE85plus = Math.max(priceStage1 + 100, 590);

        if (price < 490) {
            priceE85 = 490;
            priceE85plus = 590;
        }
    }

    if (brandConfig.brand === 'shiftech') {
        basePrice = price + 9;
        priceStage1 = Math.max(basePrice, 399);
        priceStage2 = priceStage1 + 200;

        priceE85 = Math.max(priceStage1, 599);
        priceE85plus = Math.max(priceStage1 + 200, 799);

        if (price < 590) {
            priceE85 = 599;
            priceE85plus = 799;
        }
    }

    switch (type) {
        case 'stage1':
            return priceStage1;
        case 'stage2':
            return priceStage2;
        case 'e85':
            return priceE85;
        case 'e85plus':
            return priceE85plus;
        default:
            return price;
    }
}
const activeTabEngineDetails = ref('stage1Tab')
function showCarEngineDetails() {
    getEngineDetails(form.car_engine_id);
    showEngineDetails.value = true;
}

const showEngineDetails = ref(false);
const carEngineDetails = ref(null);
const loadingEngineDetails = ref(true);
function getEngineDetails(idApiEngine) {
    loadingEngineDetails.value = true;
    axios.get('/api/chiptuning/id-api/' + idApiEngine)
        .then(response => {
            carEngineDetails.value = response.data;
            loadingEngineDetails.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const loadingFillCarDetails = ref(false);
async function fillCarDetails() {
    isInitialized.value = false;
    loadingFillCarDetails.value = true;
    //Populate the configurator
    if (client.value.data.vehicle_type !== null) {
        form.vehicle_type = client.value.data.vehicle_type;
        await getCarBrands();
    }
    if (client.value.data.car_brand_id !== null) {
        form.car_brand_id = client.value.data.car_brand_id;
        await getCarModels();
    }
    if (client.value.data.car_model_id !== null) {
        form.car_model_id = client.value.data.car_model_id;
        await getCarVersions();
    }
    if (client.value.data.car_version_id !== null) {
        form.car_version_id = client.value.data.car_version_id;
        form.car_engine_id = client.value.data.car_engine_id
        await getCarEngines();
    }
    isInitialized.value = true;
    loadingFillCarDetails.value = false;
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xxl-3" v-if="!loading">
            <div class="card">
                <div class="card-body p-4">
                    <div class="text-center">
                        <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                            <img :src="brandConfig.logoSmall" class="rounded-circle avatar-xl img-thumbnail-dark shadow" alt="logo">
                        </div>
                        <h5 class="fs-16 mb-1">{{ client.data.first_name + ' ' + client.data.last_name }}</h5>
                        <span :class="'badge fs-11 mb-2 bg-'+client.data.client_status_badge_color+'-subtle  text-'+client.data.client_status_badge_color">{{ client.data.client_status_label_fr }}</span>
                        <p class="text-muted">#{{ client.data.id }}</p>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item avatar-xs" title="Appeler le client">
                                <a :href="'tel:'+client.data.phone_number" class="avatar-title bg-success-subtle text-success fs-15 rounded">
                                    <i class="ri-phone-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Envoyer un email" v-if="client.data.email !== null">
                                <a :href="'mailto:'+client.data.email" class="avatar-title bg-warning-subtle text-warning fs-15 rounded">
                                    <i class="ri-mail-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Modifier" v-if="client.can.update">
                                <router-link :to="{ name: 'clients.edit', params:{ id: client.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded">
                                    <i class="ri-edit-2-fill"></i>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Info</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                            <tr v-if="client.data.company_name !== null">
                                <th class="ps-0" scope="row">Société</th>
                                <td class="text-body">
                                    {{ client.data.company_name }}
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Téléphone :</th>
                                <td class="text-body">
                                    <a :href="'tel:'+client.data.phone_number" class="text-secondary">{{ client.data.phone_number }}</a>
                                </td>
                            </tr>
                            <tr v-if="client.data.phone_number_2 !== null">
                                <th class="ps-0" scope="row">Téléphone secondaire :</th>
                                <td class="text-body">
                                    <a :href="'tel:'+client.data.phone_number_2" class="text-secondary">{{ client.data.phone_number_2 }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">E-mail :</th>
                                <td class="text-body">
                                    <a :href="'mailto:'+client.data.email" class="text-secondary">{{ client.data.email }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Centre :</th>
                                <td class="text-body">{{ client.data.center.data.name }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">A contacter par :</th>
                                <td class="text-body">{{ client.data.preferred_contact_method !== null ? client.data.preferred_contact_method : '--' }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Origine :</th>
                                <td class="text-body">{{ client.data.referral_source !== null ? client.data.referral_source : '--' }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Dernier contact :</th>
                                <td class="text-body">{{ client.data.last_contact_fr !== null ? client.data.last_contact_fr : '--' }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Newsletter :</th>
                                <td class="text-body">
                                    <span class="badge bg-success-subtle text-success fs-11" v-if="client.data.subscribed_to_newsletter">Inscrit</span>
                                    <span class="badge bg-danger-subtle text-danger fs-11" v-else>Non inscrit</span>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Date de naissance :</th>
                                <td class="text-body">{{ client.data.birth_date_fr !== null ? client.data.birth_date_fr : '--' }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Créé le</th>
                                <td class="text-body">{{ client.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xxl-9" v-if="!loading">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">

                        <li class="nav-item" @click.prevent="activeTab = 'requestsTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'requestsTab' }" data-bs-toggle="tab" href="#requestsTab" role="tab">
                                <i class="mdi mdi-car-info me-2 align-middle fs-14"></i>Demandes
                            </a>
                        </li>

                        <li class="nav-item" @click.prevent="activeTab = 'carTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'carTab' }" data-bs-toggle="tab" href="#carTab" role="tab">
                                <i class="mdi mdi-car me-2 align-middle fs-14"></i>Véhicule
                            </a>
                        </li>

                        <li class="nav-item" @click.prevent="activeTab = 'callsTab'">
                            <a class="nav-link align-middle" :class="{ 'active': activeTab === 'callsTab' }" data-bs-toggle="tab" href="#callsTab" role="tab">
                                <i class="mdi mdi-phone me-2 align-middle fs-14"></i>Appels
                            </a>
                        </li>

                        <li class="nav-item" @click.prevent="activeTab = 'notesTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'notesTab' }" data-bs-toggle="tab" href="#notesTab" role="tab">
                                <i class="mdi mdi-notebook-edit me-2 align-middle fs-14"></i>Notes
                            </a>
                        </li>

                        <li class="nav-item" @click.prevent="activeTab = 'addressTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'addressTab' }" data-bs-toggle="tab" href="#addressTab" role="tab">
                                <i class="mdi mdi-map-marker me-2 align-middle fs-14"></i>Adresse
                            </a>
                        </li>

                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">

                        <div class="tab-pane" :class="{ 'active': activeTab === 'notesTab' }" id="notesTab" role="tabpanel">
                            <p>
                                <strong>Client à recontacter : </strong>
                                <span class="badge bg-success-subtle text-success fs-11" v-if="client.data.recontact_required">Oui</span>
                                <span class="badge bg-danger-subtle text-danger fs-11" v-else>Non</span><br>
                                <strong>Raison :</strong> {{ client.data.recontact_reason !== null ? client.data.recontact_reason : '--' }}
                            </p>
                            <p class="mb-0">
                                <strong>Notes : </strong><br>
                                <span v-html="client.data.notes !== null ? client.data.notes.replace(/\n/g, '<br />') : '--'"></span>
                            </p>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'carTab' }" id="notesTab" role="tabpanel">
                            <div>
                                <div v-if="client.data.engine" class="d-flex align-items-start ms-2">
                                    <div class="mt-3 text-center">
                                        <img :src="'/format-image/'+client.data.engine.data.brand.data.logo+'?w=60&h=60&fm=webp&fit=crop'" class="mb-3">
                                        <h4 class="mb-1">{{ client.data.engine.data.brand.data.name }}</h4>
                                        <p class="text-body fs-15 mb-0">
                                            {{ client.data.car_model }}<br>
                                            <span class="text-muted fs-13">
                                            {{ client.data.car_version  }}<br>
                                            {{ client.data.engine.data.power_type_fr }} - {{ client.data.engine.data.name }}
                                        </span>
                                        </p>
                                        <router-link v-if="client.data.engine !== null" :to="{ name: 'chiptuning.show', params:{ id: client.data.engine.data.id } }" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2">Fiche moteur</router-link>
                                    </div>
                                </div>
                                <div v-else>
                                    Autre véhicule enregistré.
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'addressTab' }" id="addressTab" role="tabpanel">
                            <div v-if="client.data.address !== null">
                                <div class="alert alert-info shadow" role="alert">
                                    <p class="fw-bold">{{ client.data.address.data.label }}</p>
                                    {{ client.data.address.data.address_line_1 }}<br />
                                    {{ client.data.address.data.postal_code + ' ' + client.data.address.data.city + ', ' + client.data.address.data.country }}
                                </div>
                                <iframe allowfullscreen loading="lazy" class="border gmaps" width="100%" height="300"
                                        :src="'https://www.google.com/maps/embed/v1/search?q='+client.data.address.data.address_line_1+'+'+client.data.address.data.postal_code+'+'+client.data.address.data.city+'+'+client.data.address.data.country+'&key=AIzaSyDmlLxZGyzUGcR0coBHSS4N1FNLbrndf-k'">
                                </iframe>
                            </div>
                            <div v-else>
                                <p class="mb-0">Aucune adresse enregistrée</p>
                            </div>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'requestsTab' }" id="requestsTab" role="tabpanel">
                            <div class="d-flex justify-content-end">
                                <button href="#" class="btn btn-sm btn-secondary mb-4" v-if="authUser.can && authUser.can.create_client_request" @click.prevent="showModalCreateClientRequest">
                                    <i class="ri-add-fill me-1 align-bottom hide-on-mobile"></i> Nouvelle demande
                                </button>
                            </div>

                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="col-1" scope="col">#ID</th>
                                        <th class="col-4" scope="col">Raison contact</th>
                                        <th class="col-2" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Statut</th>
                                        <th class="col-1" scope="col">Dernière MAJ</th>
                                        <th class="col-1" scope="col">Notifications</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                    </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                        <tr v-for="(clientRequest, index) in clientRequests" :key="index">
                                            <td class="fw-medium text-dark">#{{ clientRequest.data.id }}</td>
                                            <td>{{ clientRequest.data.contact_reason }}</td>
                                            <td>{{ clientRequest.data.center.data.name }}</td>
                                            <td>
                                                <span :class="'badge fs-11 bg-'+clientRequest.data.status_badge_color+'-subtle  text-'+clientRequest.data.status_badge_color">{{ clientRequest.data.status_label_fr }}</span>
                                            </td>
                                            <td>{{ clientRequest.data.updated_at_fr }}</td>
                                            <td class="text-center">
                                                <span v-if="clientRequest.data.notifications_center > 0" class="badge fs-11 rounded-pill bg-danger-subtle text-danger">{{ clientRequest.data.notifications_center }}</span>
                                            </td>
                                            <td class="text-center">
                                                <router-link :to="{ name: 'client-requests.show', params:{ id: clientRequest.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                            </td>
                                        </tr>
                                        <tr class="text-center font-italic text-muted" v-if="clientRequests.length === 0 && !loadingClientRequests">
                                            <td colspan="10">Aucune demande...</td>
                                        </tr>
                                        <tr v-if="loadingClientRequests">
                                            <td colspan="9">
                                                <loader></loader>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <pagination
                                    @change-page:clicked="getClientRequests($event, 'clientRequests')"
                                    :visible="!loadingClientRequests"
                                    :links="linksClientRequests"
                                    :meta="metaClientRequests"
                                ></pagination>
                            </div>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'callsTab' }" id="callsTab" role="tabpanel">
                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="col-1 text-center" scope="col">Type</th>
                                        <th class="col-1" scope="col">Numéro</th>
                                        <th class="col-1" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Date</th>
                                        <th class="col-1" scope="col">Durée</th>
                                        <th class="col-2 ps-4" scope="col">Enregistrement</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                    <tr v-for="(call, index) in calls" :key="index">
                                        <td class="text-center">
                                            <span class="badge fs-11 bg-danger-subtle text-danger" v-if="defineCallTypeBadge(call) === 'missed'">Manqué <span v-if="call.data.missed_call_count > 0"> ({{ call.data.missed_call_count }})</span></span>
                                            <span class="badge fs-11 bg-warning-subtle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'">Messagerie</span>
                                            <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="defineCallTypeBadge(call) === 'out'">Sortant</span>
                                            <span class="badge fs-11 bg-success-subtle text-success" v-if="defineCallTypeBadge(call) === 'in'">Entrant</span>
                                        </td>
                                        <td>
                                            <i class="mdi mdi-phone-outgoing me-2 fs-20 align-middle text-secondary" v-if="defineCallTypeBadge(call) === 'out'"></i>
                                            <i class="mdi mdi-phone-incoming me-2 fs-20 align-middle text-success" v-if="defineCallTypeBadge(call) === 'in'"></i>
                                            <i class="mdi mdi-phone-missed me-2 fs-20 align-middle text-danger" v-if="defineCallTypeBadge(call) === 'missed'"></i>
                                            <i class="mdi mdi-voicemail me-2 fs-20 align-middle text-warning" v-if="defineCallTypeBadge(call) === 'voicemail'"></i>
                                            <span class="fw-semibold text-dark">{{ call.data.client.data.phone_number }}</span>
                                        </td>
                                        <td>{{ call.data.center.data.name }}</td>
                                        <td>{{ call.data.start_time_fr }}</td>
                                        <td>{{ call.data.total_duration }}</td>
                                        <td class="py-0">
                                            <audio class="players" v-if="call.data.record_link !== null" preload="none">
                                                <source :src="call.data.record_link" type="audio/mp3">
                                                Your browser does not support the audio element.
                                            </audio>
                                            <audio class="players" v-if="call.data.voicemail_link !== null">
                                                <source :src="call.data.voicemail_link" type="audio/mp3">
                                                Your browser does not support the audio element.
                                            </audio>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm" @click.prevent="displayCallDetails(call)">Détails</button>
                                        </td>
                                        <td class="text-center">
                                            <!--Call-->
                                            <a :href="'tel:' + call.data.client.data.phone_number.replace(/\s+/g, '')" class="text-info" title="Appeler">
                                                <i class="ri-phone-fill fs-17 px-1"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="calls.length === 0 && !loadingCalls">
                                        <td colspan="10">Aucun appel...</td>
                                    </tr>
                                    <tr v-if="loadingCalls">
                                        <td colspan="8">
                                            <loader></loader>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <pagination
                                    @change-page:clicked="getClientCalls($event, 'clientCalls')"
                                    :visible="!loadingCalls"
                                    :links="linksCalls"
                                    :meta="metaCalls"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                    <!--Modal calls details-->
                    <div class="modal fade zoomIn" id="modalCallDetails" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content" v-if="callToDisplay !== null">
                                <div class="modal-header">
                                    <h5 class="modal-title">Appel {{ callToDisplay.data.direction === 'in' ? 'du' : 'vers le' }} <span class="text-muted fw-normal">{{ callToDisplay.data.client.data.phone_number }}</span></h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="table-responsive">
                                        <table class="table table-nowrap mb-3">
                                            <tbody>
                                            <tr>
                                                <th scope="row" class="col-4">Type</th>
                                                <td>
                                                    <span class="badge fs-11 bg-danger-subtle text-danger" v-if="defineCallTypeBadge(callToDisplay) === 'missed'">Appel manqué <span v-if="callToDisplay.data.missed_call_count > 0"> ({{ callToDisplay.data.missed_call_count }})</span></span>
                                                    <span class="badge fs-11 bg-warning-subtle text-warning" v-if="defineCallTypeBadge(callToDisplay) === 'voicemail'">Messagerie</span>
                                                    <span class="badge fs-11 bg-secondary-subtle text-secondary" v-if="defineCallTypeBadge(callToDisplay) === 'out'">Appel sortant</span>
                                                    <span class="badge fs-11 bg-success-subtle text-success" v-if="defineCallTypeBadge(callToDisplay) === 'in'">Appel entrant</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="col-4">Centre</th>
                                                <td>{{ callToDisplay.data.center.data.name }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="col-4">Client</th>
                                                <td class="align-middle">
                                                    <router-link :to="{ name: 'clients.show', params: { id: callToDisplay.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                                        {{ callToDisplay.data.client.data.last_name }} {{ callToDisplay.data.client.data.first_name }}
                                                    </router-link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!--In-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'voicemail'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0">
                                                <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index">
                                                    <div class="accordion-header" id="headingOne">
                                                        <a class="accordion-button p-2 shadow-none">
                                                            <div class="d-flex align-items-center">
                                                                <div class="flex-shrink-0 avatar-xs">
                                                                    <div class="avatar-title rounded-circle shadow" :class="(index + 1) < callToDisplay.data.missed_call_time.length ? 'bg-danger' : 'bg-warning'">
                                                                        <i class="mdi mdi-phone-missed-outline"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="flex-grow-1 ms-3">
                                                                    <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="mdi mdi-voicemail me-2 align-middle fs-16"></i>Nouveau message vocal</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Missed-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'missed'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-danger rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-missed-outline"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Out-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'out'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-secondary rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-outgoing"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel sortant - <span class="fw-normal text-muted">{{ callToDisplay.data.start_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--In-->
                                    <div class="profile-timeline" v-if="defineCallTypeBadge(callToDisplay) === 'in'">
                                        <div class="accordion accordion-flush" id="accordionFlushExample">

                                            <!-- Call hanged up -->
                                            <div class="accordion-item border-0" v-if="callToDisplay.data.missed_call_count === 0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-success rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-incoming"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel entrant - <span class="fw-normal text-muted">{{ callToDisplay.data.start_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Missed call -->
                                            <div class="accordion-item border-0" v-for="(missedCall, index) in callToDisplay.data.missed_call_time" :key="index" v-else>
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title rounded-circle shadow bg-danger" >
                                                                    <i class="mdi mdi-phone-missed-outline"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel manqué - <span class="fw-normal text-muted">{{ missedCall }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <!--Call ended-->
                                            <div class="accordion-item border-0" v-if="callToDisplay.data.answered_time_fr !== null && callToDisplay.data.missed_call_count > 0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-success rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-incoming"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel entrant - <span class="fw-normal text-muted">{{ callToDisplay.data.answered_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body ms-2 ps-5 pt-0">
                                                        <p class="mb-0 pt-3 align-middle"><i class="bx bx-time-five me-1 align-middle fs-16"></i>Durée : {{ callToDisplay.data.total_duration }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Call ended-->
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <a class="accordion-button p-2 shadow-none">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-dark rounded-circle shadow">
                                                                    <i class="mdi mdi-phone-off"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-15 mb-0 fw-semibold">Appel terminé - <span class="fw-normal text-muted">{{ callToDisplay.data.end_time_fr }}</span></h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Modal create client request-->
                    <div class="modal fade zoomIn" id="modalCreateClientRequest" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" >
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content" >
                                <div class="modal-header">
                                    <h5 class="modal-title">
                                        Nouvelle demande client
                                    </h5>
                                </div>
                                <div class="modal-body" v-if="loadingClientRequestCreate">
                                    <loader/>
                                </div>
                                <form v-on:submit.prevent="createClientRequest" v-if="!loadingClientRequestCreate">
                                <div class="modal-body row">
                                    <form-select-client
                                        :errors="errors"
                                        label="Client"
                                        name="client_id"
                                        :required="true"
                                        :data="form.client_id"
                                        @update:field="form.client_id = ($event !== null ? $event.id : null)"
                                        bs-class="col-xl-6"
                                        :disabled="true"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un client"
                                        :client-pre-selected="clientPreSelected"
                                    />


                                    <form-select-center
                                        :errors="errors"
                                        label="Centre"
                                        name="center_id"
                                        :required="true"
                                        :data="form.center_id"
                                        @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                        bs-class="col-xl-6"
                                        :disabled="false"
                                        :taggable="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un centre"
                                        :center-pre-selected="centerPreSelected"
                                    />

                                    <form-select-field
                                        name="status_request"
                                        label="Statut"
                                        :label-hidden="false"
                                        @update:field="form.status = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.status"
                                        bs-class="col-xl-6"
                                        :liste-options="[
                                            { text: 'À traiter', value: 'pending' },
                                            { text: 'Attente d\'information', value: 'awaiting_information' },
                                            { text: 'Répondu', value: 'responded' },
                                            { text: 'RDV programmé', value: 'appointment_scheduled' },
                                            { text: 'RDV en cours', value: 'appointment_scheduled' },
                                            { text: 'Gagné', value: 'won' },
                                            { text: 'Client non présenté', value: 'no_show' },
                                            { text: 'Perdu', value: 'lost' },
                                            { text: 'Fermé', value: 'closed' }
                                        ]"
                                    />

                                    <form-select-field
                                        name="request_origin"
                                        label="Origine demande"
                                        :label-hidden="false"
                                        @update:field="form.request_origin = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="form.request_origin"
                                        bs-class="col-xl-6"
                                        :disabled="false"
                                        :liste-options="[
                                            { text: 'Comptoir', value: 'Comptoir' },
                                            { text: 'Événement', value: 'Événement' },
                                            { text: 'Influenceur', value: 'Influenceur' },
                                            { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                            { text: 'RingOver', value: 'RingOver' },
                                            { text: 'ADS', value: 'ADS' },
                                            { text: 'Site web', value: 'Site web' },
                                            { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                            { text: 'Campagne SMS', value: 'Campagne SMS' },
                                            { text: 'Campagne Mail', value: 'Campagne Mail' },
                                            { text: 'Parrainage', value: 'Parrainage' },
                                            { text: 'Landing page', value: 'Landing page' },
                                        ]"
                                    />

                                    <form-select-field
                                        name="contact_reason"
                                        label="Raison contact"
                                        :label-hidden="false"
                                        @update:field="form.contact_reason = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="form.contact_reason"
                                        bs-class="col-xl-6"
                                        :liste-options="[
                                            { text: 'Rendez-vous', value: 'Rendez-vous' },
                                            { text: 'Demande de rappel', value: 'Demande de rappel' },
                                            { text: 'Devis', value: 'Devis' },
                                            { text: 'Demande d’information', value: 'Demande d’information' },
                                            { text: 'Devenir franchisé', value: 'Devenir franchisé' },
                                            { text: 'Reprog. en Attente', value: 'Reprog. en Attente' },
                                            { text: 'Participer au Dev.', value: 'Participer au Dev.' },
                                            { text: 'Assistance', value: 'Assistance' },
                                            { text: 'JOBS', value: 'JOBS' },
                                            { text: 'Autre', value: 'Autre' }
                                        ]"
                                    />

                                    <form-select-field
                                        name="car_stage"
                                        label="Prestation souhaitée"
                                        :label-hidden="false"
                                        @update:field="form.car_stage = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="form.car_stage"
                                        bs-class="col-xl-6"
                                        :liste-options="[
                                            { text: 'Reprogrammation stage 0', value: 'Reprogrammation stage 0' },
                                            { text: 'Reprogrammation stage 1', value: 'Reprogrammation stage 1' },
                                            { text: 'Reprogrammation stage 2', value: 'Reprogrammation stage 2' },
                                            { text: 'Reprogrammation stage 3', value: 'Reprogrammation stage 3' },
                                            { text: 'Boîte de vitesses', value: 'Boîte de vitesses' },
                                            { text: 'Conversion E85', value: 'Conversion E85' },
                                            { text: 'Conversion E85+', value: 'Conversion E85+' },
                                            { text: 'Pièces performance', value: 'Pièces performance' },
                                            { text: 'Entretien', value: 'Entretien' },
                                            { text: 'Passage au banc', value: 'Passage au banc' },
                                            { text: 'Esthétique', value: 'Esthétique' },
                                            { text: 'Diagnostic', value: 'Diagnostic' },
                                        ]"
                                    />

                                    <form-multi-select
                                        :errors="errors"
                                        label="Tags"
                                        name="tags"
                                        :required="false"
                                        :data="form.tags"
                                        @update:field="form.tags = $event"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                        :label-hidden="false"
                                        placeholder="Sélectionnez un ou plusieurs tags"
                                        :liste-options="[
                                            { name: 'Promotion', id: 'Promotion' },
                                            { name: 'ECU en dev', id: 'ECU en dev' },
                                            { name: 'SAV', id: 'SAV' },
                                            { name: 'Remise Prépa', id: 'Remise Prépa' },
                                            { name: 'Remise origine', id: 'Remise origine' },
                                            { name: 'Franchise', id: 'Franchise' },
                                            { name: 'Jobs', id: 'Jobs' },
                                            { name: 'Jeux-concours', id: 'Jeux-concours' },
                                            { name: 'Doublon', id: 'Doublon' },
                                        ]"
                                    />

                                    <form-switch
                                        name="is_on_promotion"
                                        label="Est en promotion ?"
                                        :label-hidden="false"
                                        @update:field="form.is_on_promotion = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.is_on_promotion"
                                        bs-class="col-md-12"
                                        :disabled="false"
                                    />

                                    <form-textarea
                                        name="notes"
                                        label="Notes"
                                        :label-hidden="false"
                                        @update:field="form.notes = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.notes"
                                        bs-class="col-xl-12"
                                        :row="1"
                                    />

                                    <form-select-field
                                        name="vehicle_type"
                                        label="Type véhicule"
                                        :label-hidden="false"
                                        @update:field="form.vehicle_type = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.vehicle_type"
                                        bs-class="col-xl-6"
                                        :disabled="false"
                                        :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                        :liste-options="[
                                            { text: 'Voiture', value: 'car' },
                                            { text: 'Agricole', value: 'agri' },
                                            { text: 'ATV', value: 'atv' },
                                            { text: 'Camion', value: 'trucks' },
                                            { text: 'Jetski', value: 'jetski' },
                                            { text: 'Moto', value: 'moto' },
                                            { text: 'Réinitialiser configurateur', value: null },
                                        ]"
                                    />

                                    <form-select-field
                                        name="car_brand_id"
                                        label="Marques"
                                        :label-hidden="false"
                                        @update:field="form.car_brand_id = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.car_brand_id"
                                        bs-class="col-xl-6"
                                        :liste-options="carBrands"
                                        :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                        :disabled="form.vehicle_type === null"
                                    />

                                    <form-select-field
                                        name="car_model_id"
                                        label="Modèles"
                                        :label-hidden="false"
                                        @update:field="form.car_model_id = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.car_model_id"
                                        bs-class="col-xl-6"
                                        :disabled="form.car_brand_id === null"
                                        :liste-options="carModels"
                                        :placeholder="form.car_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                                    />

                                    <form-select-field
                                        name="car_version_id"
                                        label="Versions"
                                        :label-hidden="false"
                                        @update:field="form.car_version_id = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.car_version_id"
                                        bs-class="col-xl-6"
                                        :disabled="form.car_model_id === null"
                                        :liste-options="carVersions"
                                        :placeholder="form.car_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                                    />

                                    <form-select-field
                                        name="car_engine_id"
                                        label="Motorisation"
                                        :label-hidden="false"
                                        @update:field="form.car_engine_id = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="form.car_engine_id"
                                        bs-class="col-xl-12"
                                        :disabled="form.car_version_id === null"
                                        :liste-options="carEngines"
                                        :placeholder="form.car_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                        :margin="form.car_engine_id === null ? 'mb-3' : 'mb-2'"
                                    />
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn-sm btn-info bg-gradient waves-effect waves-light me-1 align-middle" @click.prevent="fillCarDetails()" v-if="client.data.car_engine_id !== null">
                                            <span v-if="loadingFillCarDetails" class="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
                                            <span class="align-middle" v-else>Remplir avec véhicule client</span>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showCarEngineDetails()" v-if="form.car_engine_id !== null" >Fiche moteur</button>
                                    </div>

                                    <!--Engine prices-->
                                    <div v-if="!loadingEngineDetails && carEngineDetails !== null && showEngineDetails" class="mt-3">
                                        <!--Chiptuning-->
                                        <ul class="mt-0 mb-3 nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                                            <li class="nav-item" @click.prevent="activeTabEngineDetails = 'stage1Tab'">
                                                <a class="nav-link stage1" :class="{ 'active': activeTabEngineDetails === 'stage1Tab' }" data-bs-toggle="tab" href="#stage1Tab" role="tab">
                                                    Stage 1
                                                </a>
                                            </li>
                                            <li class="nav-item" @click.prevent="activeTabEngineDetails = 'stage2Tab'">
                                                <a class="nav-link stage2" :class="{ 'active': activeTabEngineDetails === 'stage2Tab' }" data-bs-toggle="tab" href="#stage2Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                                    Stage 2
                                                </a>
                                            </li>
                                            <li class="nav-item" @click.prevent="activeTabEngineDetails = 'e85Tab'">
                                                <a class="nav-link e85" :class="{ 'active': activeTabEngineDetails === 'e85Tab' }" data-bs-toggle="tab" href="#e85Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                                    E85
                                                </a>
                                            </li>
                                            <li class="nav-item" @click.prevent="activeTabEngineDetails = 'e85+Tab'">
                                                <a class="nav-link e85plus" :class="{ 'active': activeTabEngineDetails === 'e85+Tab' }" data-bs-toggle="tab" href="#e85+Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                                    E85+
                                                </a>
                                            </li>
                                            <li class="nav-item" @click.prevent="activeTabEngineDetails = 'dsg'" v-if="carEngineDetails.data.dsg_available">
                                                <a class="nav-link" :class="{ 'active': activeTabEngineDetails === 'dsg' }" data-bs-toggle="tab" href="#dsg" role="tab">
                                                    Boîte de vitesses
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <!--Stage1-->
                                            <div class="tab-pane" :class="{ 'active': activeTabEngineDetails === 'stage1Tab' }" id="stage1Tab" role="tabpanel">
                                                <p class="text-body mb-3">
                                                    La reprogrammation moteur Stage 1 consiste à optimiser la gestion du moteur pour améliorer les performances de votre véhicule et diminuer la consommation de carburant . C'est l'option parfaite pour une meilleure expérience de conduite sans modifications mécaniques.
                                                </p>
                                                <div class="table-responsive" v-if="carEngineDetails.data.developed">
                                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" class="col-6"></th>
                                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td class="fw-bold">Origine</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold text-dark">Gain</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold stage-1">Stage 1</td>
                                                            <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                            <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="fs-15">
                                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">{{ calculatePrice('stage1', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                    </p>
                                                </div>
                                                <div v-else>
                                                    <p class="fs-15">
                                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">Bientôt disponible</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--Stage2-->
                                            <div class="tab-pane" :class="{ 'active': activeTabEngineDetails === 'stage2Tab' }" id="stage2Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                                <p class="text-body mb-3">
                                                    Le Stage 2 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" class="col-6"></th>
                                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td class="fw-bold">Origine</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold text-dark">Gain</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_horsepower_gain }} ch</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_torque_gain }}  Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold stage-2">Stage 2</td>
                                                            <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_horsepower_total }} ch</td>
                                                            <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_torque_total }} Nm</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="fs-15">
                                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ calculatePrice('stage2', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--E85-->
                                            <div class="tab-pane" :class="{ 'active': activeTabEngineDetails === 'e85Tab' }" id="e85Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                                <p class="text-body mb-3">
                                                    La conversion E85 Flex-Fuel offre à votre véhicule la flexibilité de rouler au Bioéthanol, à l'essence, ou à un mélange des deux, sans contrainte. Cette adaptation réduit de moitié le coût du carburant et diminue notablement les émissions de CO2, réduisant ainsi l'impact environnemental.
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" class="col-6"></th>
                                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td class="fw-bold">Origine</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold text-dark">Gain</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold e-85">E85</td>
                                                            <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.horsepower }} ch</td>
                                                            <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.torque }} Nm</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="fs-15">
                                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85"></i> <span class=" fw-bold">{{ calculatePrice('e85', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--E85+-->
                                            <div class="tab-pane" :class="{ 'active': activeTabEngineDetails === 'e85+Tab' }" id="e85+Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                                <p class="text-body mb-3">
                                                    La conversion E85+ Flex-Fuel permet à votre véhicule de fonctionner avec du Bioéthanol, de l'essence, ou un mélange des deux carburants, tout en bénéficiant de l’augmentation de puissance d’un stage 1. En plus de réduire le coût du carburant de moitié, elle diminue aussi les émissions de CO2, tout en améliorant significativement les performances.
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" class="col-6"></th>
                                                            <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                            <th scope="col" class="text-center col-3">COUPLE</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td class="fw-bold">Origine</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                            <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold text-dark">Gain</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                            <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold e-85plus">E85+</td>
                                                            <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                            <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="fs-15">
                                                        <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85plus"></i> <span class=" fw-bold">{{ calculatePrice('e85plus', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!--DSG-->
                                            <div class="tab-pane" :class="{ 'active': activeTabEngineDetails === 'dsg' }" id="dsg" role="tabpanel" v-if="carEngineDetails.data.dsg_available">
                                                <p class="text-body mb-3">
                                                    La reprogrammation des boîtes automatiques modernes optimise leur potentiel en réduisant le temps de passage des rapports, augmentant le régime et le couple maximaux et améliorant la réactivité.
                                                </p>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle"></i> <span class=" fw-bold">390 €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
