<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <multiselect
            v-model="colorSelected"
            :options="colors"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="placeholder"
            label="name"
            track-by="id"
            :preselect-first="false"
            :hide-selected="false"
            select-label=""
            selected-label="Sélectionné"
            deselect-label=""
        >
            <template #option="{ option }">
                <div v-html="customLabel(option)"></div>
            </template>
            <template #singleLabel="{ option }">
                <div v-html="customLabel(option)"></div>
            </template>
        </multiselect>
        <span class="error-message" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
export default {
    name: "FormSelectColors",
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        labelHidden: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent: {
            type: String,
            required: false,
            default: null
        },
        reference: {
            type: String,
            required: false,
            default: 'color-select'
        },
        colorPreSelected: {
            type: Number,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        }
    },
    data() {
        return {
            colorSelected: {
                id: '7',
                name: 'Stage 1/2/3',
                color: '#039BE5'
            },
            colors: [
                { id: '9', name: 'Stage 0', color: '#3F51B5' },
                { id: '7', name: 'Stage 1/2/3', color: '#039BE5' },
                { id: '2', name: 'FlexFuel', color: '#33B679' },
                { id: '6', name: 'FlexFuel+', color: '#F4511E' },
                { id: '11', name: 'SAV', color: '#D50000' },
                { id: '3', name: 'Passage au banc', color: '#8E24AA' },
                { id: '5', name: 'Mécanique', color: '#F6BF26' },
                { id: '8', name: 'Esthétique', color: '#616161' },
                { id: '12', name: 'Remise Origine', color: '#7B3F00' }
            ],
            loading: false,
        }
    },
    methods: {
        clearErrors() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject() {
            return {
                "is-invalid": this.hasError
            };
        },
        errorMessage() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        customLabel(color) {
            return `
                <div style="display: flex; align-items: center;">
                    <span style="background-color: ${color.color}; width: 20px; height: 20px; border-radius: 50%; margin-right: 10px;"></span>
                    <span>${color.name}</span>
                </div>
            `;
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        colorSelected(newVal) {
            if (newVal === null) {
                this.$emit("update:field", null);
            } else {
                this.clearErrors();
                this.$emit("update:field", this.colorSelected);
            }
        },
        colorPreSelected: {
            immediate: true,
            handler(newVal) {
                if (newVal !== null) {
                    const selectedColor = this.colors.find(color => color.id === newVal.toString());
                    if (selectedColor) {
                        this.colorSelected = {
                            id: selectedColor.id,
                            name: selectedColor.name,
                            color: selectedColor.color
                        };
                        this.$emit("update:field", this.colorSelected);
                    }
                }
            }
        }
    }
}
</script>
