<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {inject, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import {useToast} from "vue-toast-notification";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormSelectClient from "../../Components/FormSelectClient.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import FormTextField from "../../Components/FormTextField.vue";
import axios from "axios";
import FormMultiSelect from "../../Components/FormMultiSelect.vue";
import {userIsAdmin, userIsBackOffice} from "../../Utils/userRoleUtils.js";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const brandConfig = inject('brandConfig');

const loading = ref(false)
const isInitialized = ref(false)
const form = reactive( {
    client_id: null,
    center_id: null,
    status: null,
    reason_for_loss: null,
    tags: [],
    car_brand_id: null,
    car_model_id: null,
    car_version_id: null,
    car_engine_id: null,
    car_stage: null,
    contact_reason: null,
    vehicle_type: null,
    notes: null,
    is_on_promotion: false,
    request_origin: null,
    vin: null,
    license_plate: null,
    vehicle_year: null,
    mileage: null,
    spark_plug_mileage: null,
    coil_mileage: null,
    octane_rating: null,
    price: null,
    currency: null
})
const centerPreSelected = ref([])
const clientPreSelected = ref([])
const tagsPreSelected = ref([])
const errors = ref(null)
function updateClientRequest() {

    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (key === 'tags') {
            return value.forEach((tag) => {
                formData.append(`${key}[]`, tag.id);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/client-requests/" + router.currentRoute.value.params.id, formData)
        .then(() => {
            $toast.success('Demande client modifiés avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

function getClientRequest() {
    loading.value = true;
    axios.get("/api/client-requests/" + router.currentRoute.value.params.id)
        .then(async response => {
            //Populate the configurator
            if (response.data.data.vehicle_type !== null) {
                form.vehicle_type = response.data.data.vehicle_type;
                await getCarBrands();
            }
            if (response.data.data.car_brand_id !== null) {
                form.car_brand_id = response.data.data.car_brand_id;
                await getCarModels();
            }
            if (response.data.data.car_model_id !== null) {
                form.car_model_id = response.data.data.car_model_id;
                await getCarVersions();
            }
            if (response.data.data.car_version_id !== null) {
                form.car_version_id = response.data.data.car_version_id;
                form.car_engine_id = response.data.data.car_engine_id;
                await getCarEngines();
            }

            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'center') {
                    centerPreSelected.value = value;
                    return;
                }
                if (key === 'client') {
                    clientPreSelected.value = value;
                    return;
                }
                if (key === 'tags') {
                    tagsPreSelected.value = value;
                    return;
                }
                if (Object.hasOwn(form, key) && key !== 'address') {
                    form[key] = value;
                }
            });
            loading.value = false;
            isInitialized.value = true;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getClientRequest()
})

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_brand_id = null;
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carModels.value = [];
            if (form.car_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?car_brand_id=' + form.car_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            form.car_version_id = null;
            form.car_engine_id = null;
            carVersions.value = [];
            if (form.car_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?car_model_id=' + form.car_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name === null ? model.data.from : model.data.from + ' - ' + model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_version_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            carEngines.value = [];
            form.car_engine_id = null;
            if (form.car_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&car_version_id=' + form.car_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_fr + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function calculatePrice(type, price) {

    let basePrice = price;
    let priceStage1 = 0;
    let priceStage2 = 0;
    let priceE85 = 0;
    let priceE85plus = 0;

    if (brandConfig.brand === 'rstronic') {
        basePrice = price - 100;
        priceStage1 = Math.max(basePrice, 350);
        priceStage2 = priceStage1 + 100;
        priceE85 = Math.max(priceStage1, 490);
        priceE85plus = Math.max(priceStage1 + 100, 590);

        if (price < 490) {
            priceE85 = 490;
            priceE85plus = 590;
        }
    }

    if (brandConfig.brand === 'shiftech') {
        basePrice = price + 9;
        priceStage1 = Math.max(basePrice, 399);
        priceStage2 = priceStage1 + 200;

        priceE85 = Math.max(priceStage1, 599);
        priceE85plus = Math.max(priceStage1 + 200, 799);

        if (price < 590) {
            priceE85 = 599;
            priceE85plus = 799;
        }
    }

    switch (type) {
        case 'stage1':
            return priceStage1;
        case 'stage2':
            return priceStage2;
        case 'e85':
            return priceE85;
        case 'e85plus':
            return priceE85plus;
        default:
            return price;
    }
}
const activeTab = ref('stage1Tab')
function showModalEngine() {
    getEngineDetails(form.car_engine_id);
    const modaleEngineDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEngineDetails'))
    modaleEngineDetails.show();
}

const carEngineDetails = ref(null);
const loadingEngineDetails = ref(true);
function getEngineDetails(idApiEngine) {
    loadingEngineDetails.value = true;
    axios.get('/api/chiptuning/id-api/' + idApiEngine)
        .then(response => {
            carEngineDetails.value = response.data;
            loadingEngineDetails.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="updateClientRequest" v-show="!loading">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations demande</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-select-client
                                :errors="errors"
                                label="Client"
                                name="client_id"
                                :required="true"
                                :data="form.client_id"
                                @update:field="form.client_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un client"
                                :client-pre-selected="clientPreSelected"
                            />


                            <form-select-center
                                :errors="errors"
                                label="Centre"
                                name="center_id"
                                :required="true"
                                :data="form.center_id"
                                @update:field="form.center_id = ($event !== null ? $event.id : null)"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un centre"
                                :center-pre-selected="centerPreSelected"
                            />

                            <form-select-field
                                name="status_request"
                                label="Statut"
                                :label-hidden="false"
                                @update:field="form.status = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.status"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'À traiter', value: 'pending' },
                                    { text: 'Attente d\'information', value: 'awaiting_information' },
                                    { text: 'Répondu', value: 'responded' },
                                    { text: 'RDV programmé', value: 'appointment_scheduled' },
                                    { text: 'RDV en cours', value: 'appointment_scheduled' },
                                    { text: 'Gagné', value: 'won' },
                                    { text: 'Client non présenté', value: 'no_show' },
                                    { text: 'Perdu', value: 'lost' },
                                    { text: 'Fermé', value: 'closed' }
                                ]"
                            />

                            <form-select-field
                                name="request_origin"
                                label="Origine demande"
                                :label-hidden="false"
                                @update:field="form.request_origin = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.request_origin"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Comptoir', value: 'Comptoir' },
                                    { text: 'Événement', value: 'Événement' },
                                    { text: 'Influenceur', value: 'Influenceur' },
                                    { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                    { text: 'RingOver', value: 'RingOver' },
                                    { text: 'ADS', value: 'ADS' },
                                    { text: 'Site web', value: 'Site web' },
                                    { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                    { text: 'Campagne SMS', value: 'Campagne SMS' },
                                    { text: 'Campagne Mail', value: 'Campagne Mail' },
                                    { text: 'Parrainage', value: 'Parrainage' },
                                    { text: 'Landing page', value: 'Landing page' },
                                ]"
                            />

                            <form-select-field
                                v-show="form.status === 'lost'"
                                name="reason_for_loss"
                                label="Raison de la perte"
                                :label-hidden="false"
                                @update:field="form.reason_for_loss = $event"
                                :errors="errors"
                                :required="form.status === 'lost'"
                                :data="form.reason_for_loss"
                                bs-class="col-xl-12"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Doublon', value: 'Doublon' },
                                    { text: 'Plus de réponse', value: 'Plus de réponse' },
                                    { text: 'Prix', value: 'Prix' },
                                    { text: 'Concurrent', value: 'Concurrent' },
                                    { text: 'Délais', value: 'Délais' },
                                    { text: 'Perte de confiance', value: 'Perte de confiance' },
                                    { text: 'Hors sujet', value: 'Hors sujet' },
                                    { text: 'Garantie', value: 'Garantie' },
                                    { text: 'Assurance', value: 'Assurance' },
                                ]"
                            />

                            <form-select-field
                                name="contact_reason"
                                label="Raison contact"
                                :label-hidden="false"
                                @update:field="form.contact_reason = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.contact_reason"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Rendez-vous', value: 'Rendez-vous' },
                                    { text: 'Demande de rappel', value: 'Demande de rappel' },
                                    { text: 'Devis', value: 'Devis' },
                                    { text: 'Demande d’information', value: 'Demande d’information' },
                                    { text: 'Devenir franchisé', value: 'Devenir franchisé' },
                                    { text: 'Reprog. en Attente', value: 'Reprog. en Attente' },
                                    { text: 'Participer au Dev.', value: 'Participer au Dev.' },
                                    { text: 'Assistance', value: 'Assistance' },
                                    { text: 'JOBS', value: 'JOBS' },
                                    { text: 'Autre', value: 'Autre' }
                                ]"
                            />

                            <form-select-field
                                name="car_stage"
                                label="Prestation souhaitée"
                                :label-hidden="false"
                                @update:field="form.car_stage = $event"
                                :errors="errors"
                                :required="!userIsAdmin() && !userIsBackOffice()"
                                :data="form.car_stage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Reprogrammation stage 0', value: 'Reprogrammation stage 0' },
                                    { text: 'Reprogrammation stage 1', value: 'Reprogrammation stage 1' },
                                    { text: 'Reprogrammation stage 2', value: 'Reprogrammation stage 2' },
                                    { text: 'Reprogrammation stage 3', value: 'Reprogrammation stage 3' },
                                    { text: 'Boîte de vitesses', value: 'Boîte de vitesses' },
                                    { text: 'Conversion E85', value: 'Conversion E85' },
                                    { text: 'Conversion E85+', value: 'Conversion E85+' },
                                    { text: 'Pièces performance', value: 'Pièces performance' },
                                    { text: 'Entretien', value: 'Entretien' },
                                    { text: 'Passage au banc', value: 'Passage au banc' },
                                    { text: 'Esthétique', value: 'Esthétique' },
                                    { text: 'Diagnostic', value: 'Diagnostic' },
                                ]"
                            />

                            <form-switch
                                name="is_on_promotion"
                                label="Est en promotion ?"
                                :label-hidden="false"
                                @update:field="form.is_on_promotion = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.is_on_promotion"
                                bs-class="col-md-12"
                                :disabled="false"
                            />

                            <form-numeric-field
                                name="price"
                                label="Prix"
                                :label-hidden="false"
                                @update:field="form.price = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.price"
                                bs-class="col-md-6"
                                :min="0"
                                :step="0.01"
                            />

                            <form-select-field
                                name="currency"
                                label="Devise"
                                :label-hidden="false"
                                @update:field="form.currency = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.currency"
                                bs-class="col-md-6"
                                :liste-options="[
                                    { text: 'EUR', value: 'EUR' },
                                ]"
                            />

                            <form-multi-select
                                :errors="errors"
                                label="Tags"
                                name="tags"
                                :required="false"
                                :data="form.tags"
                                @update:field="form.tags = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :label-hidden="false"
                                placeholder="Sélectionnez un ou plusieurs tags"
                                :options-pre-selected="tagsPreSelected"
                                :liste-options="[
                                    { name: 'Promotion', id: 'Promotion' },
                                    { name: 'ECU en dev', id: 'ECU en dev' },
                                    { name: 'SAV', id: 'SAV' },
                                    { name: 'Remise Prépa', id: 'Remise Prépa' },
                                    { name: 'Remise origine', id: 'Remise origine' },
                                    { name: 'Franchise', id: 'Franchise' },
                                    { name: 'Jobs', id: 'Jobs' },
                                    { name: 'Jeux-concours', id: 'Jeux-concours' },
                                    { name: 'Doublon', id: 'Doublon' },
                                ]"
                            />

                            <form-textarea
                                name="notes"
                                label="Notes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="2"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-car me-2 align-middle fs-4"></i>Véhicule concerné</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-select-field
                                name="vehicle_type"
                                label="Type véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_type = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_type"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                :liste-options="[
                                    { text: 'Voiture', value: 'car' },
                                    { text: 'Agricole', value: 'agri' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'trucks' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Réinitialiser configurateur', value: null },
                                ]"
                            />

                            <form-select-field
                                name="car_brand_id"
                                label="Marques"
                                :label-hidden="false"
                                @update:field="form.car_brand_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_brand_id"
                                bs-class="col-xl-6"
                                :liste-options="carBrands"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :disabled="form.vehicle_type === null"
                            />

                            <form-select-field
                                name="car_model_id"
                                label="Modèles"
                                :label-hidden="false"
                                @update:field="form.car_model_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_model_id"
                                bs-class="col-xl-6"
                                :disabled="form.car_brand_id === null"
                                :liste-options="carModels"
                                :placeholder="form.car_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                            />

                            <form-select-field
                                name="car_version_id"
                                label="Versions"
                                :label-hidden="false"
                                @update:field="form.car_version_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_version_id"
                                bs-class="col-xl-6"
                                :disabled="form.car_model_id === null"
                                :liste-options="carVersions"
                                :placeholder="form.car_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                            />

                            <form-select-field
                                name="car_engine_id"
                                label="Motorisation"
                                :label-hidden="false"
                                @update:field="form.car_engine_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_engine_id"
                                bs-class="col-xl-12"
                                :disabled="form.car_version_id === null"
                                :liste-options="carEngines"
                                :placeholder="form.car_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                :margin="form.car_engine_id === null ? 'mb-3' : 'mb-2'"
                            />
                            <div class="col-md-12 mb-3" v-if="form.car_engine_id !== null">
                                <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showModalEngine()">Fiche moteur</button>
                            </div>

                            <form-text-field
                                name="vin"
                                label="VIN"
                                :label-hidden="false"
                                @update:field="form.vin = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vin"
                                bs-class="col-md-6"
                                :show-char-count="true"
                                :max-length="17"
                            />

                            <form-text-field
                                name="license_plate"
                                label="Immatriculation"
                                :label-hidden="false"
                                @update:field="form.license_plate = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.license_plate"
                                bs-class="col-md-6"
                            />

                            <form-numeric-field
                                name="vehicle_year"
                                label="Année du véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_year = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_year"
                                bs-class="col-md-6"
                                :step="1"
                                :min="1900"
                                :max="new Date().getFullYear()"
                            />

                            <form-numeric-field
                                name="mileage"
                                label="Kilométrage"
                                :label-hidden="false"
                                @update:field="form.mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.mileage"
                                bs-class="col-md-6"
                                :step="1"
                                :min="0"
                            />

                            <form-select-field
                                name="spark_plug_mileage"
                                label="Kilométrage des bougies"
                                :label-hidden="false"
                                @update:field="form.spark_plug_mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.spark_plug_mileage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Moins de 20 000 km', value: 'Moins de 20 000 km' },
                                    { text: 'Entre 20 et 50 000 km', value: 'Entre 20 et 50 000 km' },
                                    { text: 'Plus de 50 000 km', value: 'Plus de 50 000 km' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <form-select-field
                                name="coil_mileage"
                                label="Kilométrage des bobines"
                                :label-hidden="false"
                                @update:field="form.coil_mileage = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.coil_mileage"
                                bs-class="col-xl-6"
                                :liste-options="[
                                    { text: 'Moins de 20 000 km', value: 'Moins de 20 000 km' },
                                    { text: 'Entre 20 et 50 000 km', value: 'Entre 20 et 50 000 km' },
                                    { text: 'Plus de 50 000 km', value: 'Plus de 50 000 km' },
                                    { text: 'Je ne sais pas', value: 'Je ne sais pas' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <form-select-field
                                name="octane_rating"
                                label="Indice Octane"
                                :label-hidden="false"
                                @update:field="form.octane_rating = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.octane_rating"
                                bs-class="col-xl-12"
                                :liste-options="[
                                    { text: 'SP95', value: 'SP95' },
                                    { text: 'SP98', value: 'SP98' },
                                    { text: 'SP100/102', value: 'SP100/102' },
                                    { text: 'E85', value: 'E85' },
                                    { text: 'E85/SP95 (Mix)', value: 'E85/SP95 (Mix)' },
                                ]"
                                information-content="Donnée utile pour un moteur essence"
                            />

                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Modal vehicle info-->
                <div id="modalEngineDetails" class="modal fade" tabindex="-1" aria-labelledby="modalEngineDetails" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalEngineDetailsTitle"><i class="mdi mdi-engine-outline fs-22 me-2 align-middle"></i>Fiche moteur</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                                <loader v-if="loadingEngineDetails" />
                            </div>
                            <div class="modal-body" v-if="!loadingEngineDetails">
                                <div>
                                    <!--Car infos-->
                                    <div>
                                        <div class="mt-0 mx-auto text-center">
                                            <img :src="'/format-image/'+carEngineDetails.data.brand.data.logo+'?w=60&h=60&fm=webp&fit=crop'">
                                        </div>
                                        <div class="mt-3 text-center">
                                            <h4 class="mb-1">{{ carEngineDetails.data.brand.data.name }}</h4>
                                            <p class="text-body fs-15 mb-0">
                                                {{ carEngineDetails.data.model.data.name }}<br>
                                                <span class="text-muted fs-13">
                                                {{ carEngineDetails.data.version.data.name === null ? carEngineDetails.data.version.data.from : carEngineDetails.data.version.data.from + ' - ' + carEngineDetails.data.version.data.name  }}<br>
                                                {{ carEngineDetails.data.power_type_fr }} - {{ carEngineDetails.data.name }}
                                            </span>
                                            </p>
                                        </div>
                                    </div>
                                    <!--Chiptuning-->
                                    <ul class="mt-0 mb-3 nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                                        <li class="nav-item" @click.prevent="activeTab = 'stage1Tab'">
                                            <a class="nav-link stage1" :class="{ 'active': activeTab === 'stage1Tab' }" data-bs-toggle="tab" href="#stage1Tab" role="tab">
                                                Stage 1
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'stage2Tab'">
                                            <a class="nav-link stage2" :class="{ 'active': activeTab === 'stage2Tab' }" data-bs-toggle="tab" href="#stage2Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                                Stage 2
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'e85Tab'">
                                            <a class="nav-link e85" :class="{ 'active': activeTab === 'e85Tab' }" data-bs-toggle="tab" href="#e85Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                                E85
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'e85+Tab'">
                                            <a class="nav-link e85plus" :class="{ 'active': activeTab === 'e85+Tab' }" data-bs-toggle="tab" href="#e85+Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                                E85+
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'dsg'" v-if="carEngineDetails.data.dsg_available">
                                            <a class="nav-link" :class="{ 'active': activeTab === 'dsg' }" data-bs-toggle="tab" href="#dsg" role="tab">
                                                Boîte de vitesses
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <!--Stage1-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage1Tab' }" id="stage1Tab" role="tabpanel">
                                            <p class="text-body mb-3">
                                                La reprogrammation moteur Stage 1 consiste à optimiser la gestion du moteur pour améliorer les performances de votre véhicule et diminuer la consommation de carburant . C'est l'option parfaite pour une meilleure expérience de conduite sans modifications mécaniques.
                                            </p>
                                            <div class="table-responsive" v-if="carEngineDetails.data.developed">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold stage-1">Stage 1</td>
                                                        <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">{{ calculatePrice('stage1', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                            <div v-else>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">Bientôt disponible</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--Stage2-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage2Tab' }" id="stage2Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                            <p class="text-body mb-3">
                                                Le Stage 2 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold stage-2">Stage 2</td>
                                                        <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ calculatePrice('stage2', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--E85-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85Tab' }" id="e85Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                            <p class="text-body mb-3">
                                                La conversion E85 Flex-Fuel offre à votre véhicule la flexibilité de rouler au Bioéthanol, à l'essence, ou à un mélange des deux, sans contrainte. Cette adaptation réduit de moitié le coût du carburant et diminue notablement les émissions de CO2, réduisant ainsi l'impact environnemental.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold e-85">E85</td>
                                                        <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85"></i> <span class=" fw-bold">{{ calculatePrice('e85', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--E85+-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85+Tab' }" id="e85+Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                            <p class="text-body mb-3">
                                                La conversion E85+ Flex-Fuel permet à votre véhicule de fonctionner avec du Bioéthanol, de l'essence, ou un mélange des deux carburants, tout en bénéficiant de l’augmentation de puissance d’un stage 1. En plus de réduire le coût du carburant de moitié, elle diminue aussi les émissions de CO2, tout en améliorant significativement les performances.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold e-85plus">E85+</td>
                                                        <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85plus"></i> <span class=" fw-bold">{{ calculatePrice('e85plus', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--DSG-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'dsg' }" id="dsg" role="tabpanel" v-if="carEngineDetails.data.dsg_available">
                                            <p class="text-body mb-3">
                                                La reprogrammation des boîtes automatiques modernes optimise leur potentiel en réduisant le temps de passage des rapports, augmentant le régime et le couple maximaux et améliorant la réactivité.
                                            </p>
                                            <p class="fs-15">
                                                <i class="mdi mdi-arrow-right-thin fs-20 align-middle"></i> <span class=" fw-bold">390 €</span> <span class="text-muted">TTC</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer" v-if="!loadingEngineDetails">
                                <router-link :to="{ name: 'chiptuning.show', params:{ id: carEngineDetails.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light" target="_blank">Fiche détaillée</router-link>
                                <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style scoped>

</style>
